<div class="dashboard-material-theme dashboard-register" fxLayout="column" fxLayoutAlign="start center">
  <ng-container *ngrxLet="registered$ as registered">
    <mat-card class="dashboard-register__card" *ngIf="!registered && !invitedFail">
      <mat-card-content>
        <form
          [formGroup]="registerForm"
          dashboardConnectForm
          [connectForm]="isInvited ? (invitedResearcher$ | async) : (user$ | async)"
          fxLayout="column"
          fxLayoutAlign="space-around stretch"
          fxLayoutGap="20px"
          class="dashboard-register__card-form"
        >
          <mat-form-field *ngIf="!isInvited">
            <mat-label>BMS project ID</mat-label>
            <input id="register-input-project-id" matInput formControlName="projectId" placeholder="Enter Project ID" />
            <mat-error *ngIf="projectId.hasError('required')"> BMS project ID is <strong>required</strong> </mat-error>
            <mat-error *ngIf="projectId.hasError('pattern')">
              You must enter <strong>10 character ID that can have letters and numbers</strong> without leading zeros
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input id="register-input-first-name" matInput formControlName="firstName" placeholder="Enter First Name" />
            <mat-error *ngIf="firstName.hasError('required')"> First Name is <strong>required</strong> </mat-error>
            <mat-error *ngIf="firstName.hasError('maxlength')">
              First Name needs to have
              <strong>at most {{ firstName.errors?.maxlength.requiredLength }}</strong>
              characters
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input id="register-input-last-name" matInput formControlName="lastName" placeholder="Enter Last Name" />
            <mat-error *ngIf="lastName.hasError('required')"> Last Name is <strong>required</strong> </mat-error>
            <mat-error *ngIf="lastName.hasError('maxlength')">
              Last Name needs to have
              <strong>at most {{ lastName.errors?.maxlength.requiredLength }}</strong>
              characters
            </mat-error>
          </mat-form-field>

          <section fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field fxFlex="50">
              <mat-label>E-mail address</mat-label>
              <input id="register-input-email" matInput formControlName="email" placeholder="example@example.com" />
              <mat-error *ngIf="email.hasError('required')"> E-mail is <strong>required</strong> </mat-error>
              <mat-error *ngIf="email.hasError('pattern')">
                E-mail must be in format <strong>example@example.com</strong>
              </mat-error>
            </mat-form-field>
            <a
              href
              (click)="$event.preventDefault(); redirectToSSO()"
              *ngIf="!isUtAccount && !isInvited"
              fxFlex="50"
              fxLayout="row"
              fxLayoutAlign="end center"
            >
              <mat-icon>arrow_forward</mat-icon>
              Register with my UT account</a
            >
          </section>

          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
            <label for="section-study-types">Study Type</label>
            <p class="dashboard-register__card-form-info">Please choose the study types you want to create</p>
            <section
              id="section-study-types"
              class="dashboard-register__card-form-section"
              formGroupName="studyTypes"
              fxLayout="row"
              fxLayoutGap="35px"
            >
              <mat-checkbox formControlName="careercompass">CC</mat-checkbox>
              <mat-checkbox formControlName="regular">TIIM</mat-checkbox>
            </section>
            <p
              class="dashboard-register__card-form--error"
              *ngIf="(studyTypes.dirty || studyTypes.touched) && studyTypes.hasError('requireCheckboxesToBeChecked')"
            >
              At least one Study Type is <strong>required</strong>
            </p>
          </div>

          <div formGroupName="passwords" *ngIf="!isUtAccount" fxLayout="column" fxLayoutGap="5px">
            <section fxLayout="column" fxLayoutGap="20px">
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input
                  id="register-input-password"
                  type="password"
                  matInput
                  formControlName="password"
                  placeholder="Enter Password"
                />
                <mat-error *ngIf="password.hasError('required')">Password is <strong>required</strong></mat-error>
                <mat-error *ngIf="password.hasError('minlength')">
                  Password needs to have
                  <strong>at least {{ password.errors?.minlength.requiredLength }}</strong>
                  characters
                </mat-error>
                <mat-error *ngIf="password.hasError('maxlength')">
                  Password needs to have
                  <strong>at most {{ password.errors?.maxlength.requiredLength }}</strong>
                  characters
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Repeat Password</mat-label>
                <input
                  id="register-input-repeat-password"
                  type="password"
                  matInput
                  formControlName="repeatPassword"
                  placeholder="Repeat Password"
                />
                <mat-error *ngIf="repeatPassword.hasError('required')">
                  Repeat Password is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="repeatPassword.hasError('minlength')">
                  Repeat Password needs to have
                  <strong>at least {{ repeatPassword.errors?.minlength.requiredLength }}</strong>
                  characters
                </mat-error>
                <mat-error *ngIf="repeatPassword.hasError('maxlength')">
                  Repeat Password needs to have
                  <strong>at most {{ repeatPassword.errors?.maxlength.requiredLength }}</strong>
                  characters
                </mat-error>
              </mat-form-field>
            </section>
            <p
              class="dashboard-register__card-form--error"
              *ngIf="(passwords.dirty || passwords.touched) && passwords.hasError('noMatch')"
            >
              You haven't entered the same password
            </p>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions class="dashboard-register__card-actions" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button color="accent" id="register-btn-back-to-login" (click)="back()">
          {{ 'Back to login page' | uppercase }}
        </button>
        <button mat-button color="accent" id="register-btn-register" (click)="register()">
          {{ 'Register' | uppercase }}
        </button>
      </mat-card-actions>
    </mat-card>

    <dashboard-register-info *ngIf="registered"></dashboard-register-info>
    <dashboard-register-fail *ngIf="invitedFail"></dashboard-register-fail>
  </ng-container>
</div>
