import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_ROUTES } from '../../app.routes';
import { Router } from '@angular/router';
import {
  EMAIL_PATTERN,
  passwordsMatchValidator,
  removeWhitespaces,
  requireCheckboxesToBeCheckedValidator,
  TEN_DIGITS_NO_LEADING_ZEROS_PATTERN,
} from '../../core/custom-form-validators';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { RegisterRequest } from '../../models/register-request';
import { Observable, Subscription } from 'rxjs';
import {
  selectInvitedResearcher,
  selectIsInvited,
  selectInvitedFail,
  selectIsUtAccount,
  selectRegistered,
  selectUser,
} from '../../state/user/user.selectors';
import { ssoLogin } from '../../core/ut-account-helper';
import { User } from '../../models/user';
import { InvitedResearcher } from '../../models/invited-researcher';
import { InvitedRegisterRequest } from '../../models/invited-register-request';
import { register, registerInvitedApplicant } from '../../state/user/user.actions';

@Component({
  selector: 'dashboard-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;

  registered$: Observable<boolean>;
  isUtAccount$: Observable<boolean>;
  isInvited$: Observable<boolean>;
  invitedFail$: Observable<boolean>;
  user$: Observable<User>;
  invitedResearcher$: Observable<InvitedResearcher>;

  subscriptions: Subscription[] = [];
  isUtAccount = false;
  isInvited = false;
  invitedFail = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.registered$ = this.store.select(selectRegistered);
    this.isUtAccount$ = this.store.select(selectIsUtAccount);
    this.isInvited$ = this.store.select(selectIsInvited);
    this.invitedFail$ = this.store.select(selectInvitedFail);
    this.user$ = this.store.select(selectUser);
    this.invitedResearcher$ = this.store.select(selectInvitedResearcher);

    this.registerForm = this.formBuilder.group({
      projectId: ['', [Validators.required, Validators.pattern(TEN_DIGITS_NO_LEADING_ZEROS_PATTERN)]],
      firstName: ['', [Validators.required, Validators.maxLength(255), removeWhitespaces]],
      lastName: ['', [Validators.required, Validators.maxLength(255), removeWhitespaces]],
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN), removeWhitespaces]],
      studyTypes: this.formBuilder.group(
        {
          careercompass: [false],
          regular: [false],
        },
        { validators: requireCheckboxesToBeCheckedValidator() }
      ),
      passwords: this.formBuilder.group(
        {
          password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(32), removeWhitespaces]],
          repeatPassword: [
            '',
            [Validators.required, Validators.minLength(8), Validators.maxLength(32), removeWhitespaces],
          ],
        },
        { validators: passwordsMatchValidator }
      ),
    });

    this.subscriptions.push(this.isUtAccount$.subscribe((isUtAccount) => (this.isUtAccount = isUtAccount)));
    this.subscriptions.push(this.isInvited$.subscribe((isInvited) => (this.isInvited = isInvited)));
    this.subscriptions.push(this.invitedFail$.subscribe((invatedFail) => (this.invitedFail = invatedFail)));

    if (this.isUtAccount) {
      this.email.disable();
    }

    if (this.isInvited) {
      this.firstName.disable();
      this.lastName.disable();
      this.email.disable();
      this.studyTypes.disable();
    }
  }

  get projectId(): AbstractControl {
    return this.registerForm.get('projectId');
  }

  get firstName(): AbstractControl {
    return this.registerForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.registerForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.registerForm.get('email');
  }

  get studyTypes(): AbstractControl {
    return this.registerForm.get('studyTypes');
  }

  get passwords(): AbstractControl {
    return this.registerForm.get('passwords');
  }

  get password(): AbstractControl {
    return this.registerForm.get('passwords.password');
  }

  get repeatPassword(): AbstractControl {
    return this.registerForm.get('passwords.repeatPassword');
  }

  back(): void {
    this.router.navigate([APP_ROUTES.LOGIN]);
  }

  getChosenStudyTypes(): any {
    const studyTypesArray = [];
    const registerFormValue = this.registerForm.getRawValue();

    for (const studyType in registerFormValue.studyTypes) {
      if (registerFormValue.studyTypes.hasOwnProperty(studyType) && registerFormValue.studyTypes[studyType]) {
        studyTypesArray.push(studyType);
      }
    }

    return studyTypesArray;
  }

  buildRegisterRequest(): RegisterRequest {
    const registerFormValue = this.registerForm.getRawValue();

    const researcher: RegisterRequest = {
      firstname: registerFormValue.firstName,
      lastname: registerFormValue.lastName,
      email: registerFormValue.email,
    };

    if (!this.isUtAccount) {
      researcher.password = registerFormValue.passwords.password;
    }

    if (!this.isInvited) {
      researcher.projectId = registerFormValue.projectId;
    }

    researcher.study_types = this.getChosenStudyTypes();

    return researcher;
  }

  handleInvitedApplicant(): void {
    const invitedApplicant: InvitedRegisterRequest = {
      password: this.registerForm.getRawValue().passwords.password,
      requestSent: true,
      studyTypes: this.getChosenStudyTypes(),
    };
    this.store.dispatch(registerInvitedApplicant({ invitedApplicant }));
  }

  register(): void {
    this.registerForm.markAllAsTouched();

    if (this.isUtAccount) {
      this.registerForm.removeControl('passwords');
    }

    if (this.isInvited) {
      this.registerForm.removeControl('projectId');
    }

    if (this.registerForm.invalid) {
      return;
    }

    if (this.isInvited) {
      this.handleInvitedApplicant();
      return;
    }

    const researcher = this.buildRegisterRequest();
    this.store.dispatch(register({ researcher }));
  }

  redirectToSSO(): void {
    ssoLogin();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
